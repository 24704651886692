import React, { useState } from "react"
import "./SimilarProperties.scss"
import SliderModule from "@components/SliderModule/SliderModule"
import { ApiRequest } from "@common/utils/api_request_utils"
import PropertyCard from "@components/PropertyCard/PropertyCard"
import useDeviceMedia from "@hooks/useDeviceMedia"
import SliderModuleSIM from "@components/SliderModule/SliderModuleSIM"
import {  getUserobjectData, isAuthenticated  } from "@starberry/myaccount-website-utils"

const SimilarProperties = ({ propId }) => {
  const [propItems, setPropItems] = React.useState([])

  const { isTablet, isXLargeScreen } = useDeviceMedia()
  const [userObjects, setUserObjects] = useState({});
  React.useEffect(() => {
    if (isAuthenticated()) {
      const getUserObjects = async () => {
          try {
              const userObjects = await getUserobjectData()
              setUserObjects(userObjects.data.data)
          } catch (e) {
              console.log("e:", e)
          }
      }
      getUserObjects()
    }
    if (propId) {
      ApiRequest(
        {
          method: "GET",
          url: `${process.env.GATSBY_STRAPI_SRC}api/stb-lists/item/property-details?pid=${propId}&ggfx_results=true`,
        },
        (result) => {
          if (result.length > 0) {
            setPropItems(result)
          }
        }
      )
    }
  }, [propId])

  if (!propItems.length) return null

  const sliderChildren = propItems.map((property) => {
    const images = JSON.parse(property?.images)
    const building = JSON.parse(property?.building)
    const parsedData = property?.ggfx_results && property?.ggfx_results?.length > 0 ? property?.ggfx_results?.map(item => {
      return {
          ...item,
          meta: item.meta ? JSON.parse(item.meta) : null,
          transforms: item.transforms ? JSON.parse(item.transforms) : null
      };
  }) : [];
    return (
      <PropertyCard
        key={property?.crm_id}
        address={property?.display_address}
        description={property?.description}
        bathrooms={property?.bathroom}
        bedrooms={property?.bedroom}
        building={building?.[0]}
        image={images?.[0]}
        images={images}
        price={property?.price}
        size={property?.floorarea_min}
        searchType={property.search_type}
        slug={property.slug}
        email={property?.crm_negotiator_id?.email}
        crm_negotiator_id={property?.crm_negotiator_id?.id}
        name={property?.crm_negotiator_id?.name}
        crmID={property.crm_id}
        bitrix_id={property?.extra?.bitrix_id}
        imagetransforms={parsedData}
        strapiID={property?.id}
        department={property?.department}
        noContact={!isTablet}
        singnatureProp={property?.search_type === "sales" && property?.price >= 20000000 && property?.department === "residential" ? true : false}
        //   index={index}
        userObjects={userObjects}
        link_to_employee={property?.link_to_employee}
      />
    )
  })

  return (
    <SliderModuleSIM
      title="Recommended for you"
      sliderChildren={sliderChildren}
      wrapperClassname="similar-properties-wrap"
      size={!isXLargeScreen ? "md" : "sm"}
      sectionClassname=" "
    />
  )
}

export default SimilarProperties
