import { TeamContactModal } from "@components/TeamContactModal/TeamContactModal"
import MailIcon from "@components/icons/MailIcon"
import MobileIcon from "@components/icons/MobileIcon"
import WhatsAppIcon from "@components/icons/WhatsAppIcon"
import { bookAViewingURL, teamsURL } from "@lib/urls"
import { addRollWhatsapp, getWhatsAppURL, getWhatsBaseURL, gtagReportConversion } from "@lib/utils"
import { Link } from "gatsby"
import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import "./PropertyNegoCard.scss"
import { PhoneIcon } from "lucide-react"
// import BookaViewingFormMini from "@components/forms/BookaViewingFormMini"
import WhatsAppIconWhite from "@components/icons/WhatsAppIconWhite"
import WhatsAppIconGreen from "@components/icons/WhatsAppIconGreen"
import WhatsAppIconWB from "@components/icons/WhatsAppIconWB"
import loadable from "@loadable/component"
// import {  SaveItem, useUserObjectState, isAuthenticated } from "@starberry/myaccount-website-utils"
import useDeviceMedia from "@hooks/useDeviceMedia"
const BookaViewingFormMini = loadable(() => import("@components/forms/BookaViewingFormMini"))
const PropertyNegoCard = ({
  negoData,
  negoID,
  property,
  propertyURL,
  team,
  message,
  team_data,
  team_key,
}) => {
  // const { services } = useUserObjectState()

  // useEffect(() => {
  //   if (isAuthenticated()) {
  //   // on page load get the users saved objects
  //       services.getUserobject({ type: "property", state:true })
  //   }
  // }, [])
  const [show, setShow] = React.useState(false)
  const handleClose = () => setShow(false)
  const { isAboveLargeScreen } = useDeviceMedia()
  const { name, phone, url, slug, email, id,  } = negoData
  useEffect(() => {
    if(typeof window !== "undefined"){
      localStorage?.setItem("propertyURL", propertyURL)
    }
  })
  let ImgDiv = null
  if (url && name) {
    if (slug) {
      ImgDiv = (
        <Link to={`${teamsURL + slug}/`} className="img-section img-zoom">
          <img src={url} alt="nego" />
        </Link>
      )
    } else {
      ImgDiv = (
        <div className="img-section">
          <img src={url} alt="nego" />
        </div>
      )
    }
  }
  else {
    ImgDiv = (
      <Link to={`${teamsURL + "laura-adams"}/`} className="img-section img-zoom">
        <img src={"https://ggfx-providentestate.s3.eu-west-2.amazonaws.com/i.prod/laura_adams_37a79dfe04.jpg"} alt="nego" />
      </Link>
    )
  }
  const [shownum, setShowNum ] = useState(false)
 
  return (
    <div className="property-nego-card-wrap">
      <div className="top-section">
        {/* {team && (
          <button
            className="button button-orange"
            onClick={() => setShow(true)}
          >
            <span>Email</span>
            <MailIcon color="white" />
          </button>
        )} */}
        {/* {!isEmpty(property) && (
          <Link
            to={bookAViewingURL}
            className="button button-orange"
            state={{
              property: property,
              propertyURL: propertyURL,
              email:email,
              crm_negotiator_id:id,
              name: name,
            }}
            onClick={() => {
              localStorage?.setItem("propertyURL", propertyURL)
            }}
          >
            <span>{property?.publish ? "Book a Viewing" : "Contact Agent"}</span>
            <MailIcon color="white" />
          </Link>
        )} */}
        {phone ? (
          <>
            <a
              href={`tel:${phone}`}
              className="button button-orange"
              onClick={() => {
                if (isAboveLargeScreen) { 
                  setShowNum(true)
                  gtagReportConversion(`tel:${phone}`)
                }
                else {
                  gtagReportConversion(`tel:${phone}`)
                }
            }}
            >
              <PhoneIcon /> {shownum ? phone :  "Call"}
            </a>
            <a
              href={getWhatsAppURL("971505389860", message, property?.link_to_employee?.email, property?.link_to_employee?.phone, name)}
              className="button button-green"
              target="_blank"
              rel="noreferrer"
              onClick={()=> addRollWhatsapp()}
              >
              <WhatsAppIconWB color={"#fff"} /> WhatsApp
            </a>
          </>
        ) :
        <>
            <a
              href={`tel:97143233609`}
              className="button button-gray whatsapp-link"
              onClick={() => gtagReportConversion(`tel:97143233609`)}
            >
              <PhoneIcon />
            </a>
            <a
              href={getWhatsBaseURL("97143233609", message)}
              className="button button-gray whatsapp-link"
              target="_blank"
              rel="noreferrer"
              onClick={()=> addRollWhatsapp()}
              >
              <WhatsAppIcon />
            </a>
          </>
        }
      </div> 
      {/* <div className="save-btn-section">
      <SaveItem className="button button-gray" type="property" pid={property?.crm_id}>
         <span className="property-save icon-save" />
            <span className=" property-save icon-saved" />
            <span className="save-text">Save</span>
            <span className="saved-text">Saved</span>
         </SaveItem>
      
          
          </div>     */}
          {name ? 
      <div className="bottom-section">
        {ImgDiv}
        <div className="nego-info">
          <p className="name">{name}</p>
          {team_key >= 0 ?
          <p className="designation">{team_data[team_key]?.designation}</p> :
          <p className="designation">Consultant</p>}
          {negoID && <p className="orn-no">BRN No: {negoID}</p>}
        </div>
      </div>:
      <div className="bottom-section">
      {ImgDiv}
      <div className="nego-info">
        <p className="name">Laura Adams</p>
        <p className="designation">Director - Secondary Sales</p>
        <p className="orn-no">BRN No: 7870</p>
      </div>
    </div>}
    {/* {property?.link_to_employee?.email} */}
    <BookaViewingFormMini propertyURL={propertyURL} property={property} email={property?.link_to_employee?.email} crm_negotiator_id={negoData?.id} />
      {!isEmpty(team) && (
        <TeamContactModal team={team} show={show} handleClose={handleClose} />
      )}
    </div>
  )
}

export default PropertyNegoCard
